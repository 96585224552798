import React from "react"
import styled from "styled-components"
import { SectionTitle } from "@hemalr/react-blocks"
import { primaryColor } from "../../site-config"
import Navlink from "../styled-components/navlink"

const StyledDiv = styled.div`
  max-width: 1040px;
  margin: 2rem auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: minmax(auto, 360px) 1fr;
  grid-gap: 2rem;
  @media (max-width: 740px) {
    & {
      grid-template-columns: auto;
    }
  }
`
// grid-template-columns: ${props => (props.menuOnly ? "1fr" : "minmax(auto, 360px) 1fr")} ;

const StyledServicesMenu = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  align-content: start;
`

const StyledServicesContent = styled.div`
  line-height: 1.5rem;
`

const ServicesLayout = ({ children, title }) => {
  return (
    <StyledDiv>
      <StyledServicesMenu>
        <Navlink to="/services/ingrown-toenail-treatment">
          Ingrown toenails fixed
        </Navlink>
        <Navlink to="/services/corns-and-calluses-treatment">
          Hard skin (corns, calluses etc.) removed
        </Navlink>
        <Navlink to="/services/foot-skin-problems-solved">
          Skin problems solved
        </Navlink>
        <Navlink to="/services/toenail-troubles-treated">
          Toenail troubles treated
        </Navlink>
        <Navlink to="/services/diabetic-management">
          Diabetic management
        </Navlink>
        <Navlink to="/services/heel-pain-therapy-plantar-fasciitis">
          Heel pain therapy
        </Navlink>
        <Navlink to="/services/orthotics">Orthotics</Navlink>
      </StyledServicesMenu>
      <StyledServicesContent>
        <SectionTitle
          text={title}
          color={primaryColor}
          level="h1"
          size="2rem"
        />
        {children}
      </StyledServicesContent>
    </StyledDiv>
  )
}

export default ServicesLayout
