import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ServicesLayout from "../../components/services-layout"
import BookButton from "../../components/book-button"

const StyledImg = styled(Img)`
  display: inline-block;
  float: right;
  margin: 0 0 1rem 1rem;
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "services/ingrowing-toenail.png" }) {
        childImageSharp {
          fixed(height: 135) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Ingrown toenail treatment" />
      <ServicesLayout title="Ingrown toenails fixed">
        <StyledImg
          fixed={data.img.childImageSharp.fixed}
          alt="Ingrown toenail image"
        />
        <p>
          <b>YEEOOWWCH!</b>
        </p>
        <h2>What is it?</h2>
        <p>
          Ingrowing nails are very painful condition where the side of the
          toenail is curved or penetrating into the skin lying next to it. This
          causes the skin to be cut, and open to infection.
        </p>
        <h2>Why does it happen?</h2>
        <p>There are multiple causes for the issue, primary ones being:</p>
        <ol>
          <li>
            Genetics - You&apos;ve unfortunately inherited the toenail shape
            from your parents and it&apos;s not your fault.
          </li>
          <li>
            Mechanical Injury - Tight shoes that squeeze your toes together
            making the nail&apos;s side cut into your skin OR you&apos;ve
            kicked/dropped something on your toenail changing the nail&apos;s
            placement relative to the adjacent skin folds.
          </li>
        </ol>
        <h2>How do we fix it?</h2>
        <p>
          Unfortunately there are no DIY options or home cures, other than to
          manage possible infection with salt water soaks and betadine or
          antiseptic applications. However this will not stop the nail hurting
          the skin, if you attempt to cut the nail at home without sterile
          equipment, you will risk further infection.
        </p>
        <p>
          The solution involves conservatively trimming the edge of offending
          nail OR permanently through a short procedure under local anesthetic
          by your podiatrist.
        </p>
        <h2>What does the permanent fix entail?</h2>
        <p>
          The procedure takes approximately 30 minutes from beginning to end.
          You <b>will not</b> feel pain after the local anesthetic is
          administered in the first step, so you can sit back and ignore the
          podiatrist at work on your troublesome toenail.
        </p>
        <p>
          A sliver of the painful side of the toenail will be removed leaving a
          nice flat nail on top of your toe. The root of this sliver will be
          chemically burned to prevent the nail growing back.
        </p>
        <p>
          Healing completely takes 2 days to 2 weeks depending on your health
          and activities. You&apos;ll be able to walk immediately after the
          procedure, but we recommend you take at least 12 hrs rest to ensure
          complete success.
        </p>
        <h2>What happens if you don&apos;t treat it?</h2>
        <p>
          The nail will continue to offend the skin laying next to it, this will
          lead to excessive swelling, heat and redness in the area. The
          infection could dangerously start spreading up the toe threatening
          your health. This problem is easily solved in a 30 minute consult, why
          live with it a minute longer?
        </p>
        <BookButton
          text="Treat or see me for an ingrown toenail now"
          size="1.5rem"
        />
      </ServicesLayout>
    </Layout>
  )
}
